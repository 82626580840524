/********************************* Containers *********************************/

.fg-root-container {
  display: flex;
  flex-wrap: wrap;
  padding: 15px;
  width: 100%;
  margin: 0px;
  /*border: solid 2px green; margin: 1px; */
}

.fg-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  /*border: solid 2px red; margin: 1px; */
}

.fg-col {
  display: flex;
  flex-direction: column;
  /*border: solid 2px blue; margin: 1px; */
}

.fg-col-content {
  flex-basis: 0;
}

.fg-grow {
  flex-grow: 1;
}

.fg-center {
  justify-content: center;
  text-align: center;
}

/*********************************** Navbar ***********************************/

.fg-navbar {
  display: flex;
  flex-wrap: wrap;
  flex-basis: 0;
  padding-left: 10px;
  align-items: center;
  height: 60px;
  width: 100%;
  margin: 0px;
  box-shadow: 0px 1px 3px 2px #0004;
}

.fg-navbar-center {
  justify-content: center;
}

.fg-navbar-single-row {
  height: 60px;
}

.fg-navbar-active {
  font-weight: bold;
}

.fg-navbar-link {
  color: white;
  font-size: 1.2rem;
  margin-right: 10px;
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 2px;
  padding-bottom: 2px;
  border: solid 1px rgb(0,0,0,0);
  border-radius: 4px;
}

.fg-navbar-link:hover {
  color: black;
  text-decoration: none;
  font-weight: inherit;
  background-color: white;
  border: solid 1px white;
  border-radius: 4px;
}

.fg-navbar-title {
  color: white;
  font-weight: bold;
  font-size: 1.3rem;
  margin-right: 30px;
}

/********************************** Mobile ************************************/

@media only screen and (max-width: 600px) {
  .fg-root-container {
    padding: 10px;
  }

  .fg-navbar {
    height: 90px;
    padding-top: 10px;
  }

  .fg-navbar-single-row {
    height: 60px;
  }
}